
.mySwiper{
    height: 410px;
}

.mySwiper .swiper-slide {
    display: flex;
    justify-content: center;
    height: 287px;
    width: 333px;
    /* background-color: black; */
}

.mySwiper .swiper-slide div{
    border-radius: 24px !important;
}

.custom-slide{
    background-color: white;
}

/* .mySwiper .swiper-slide-active:hover .custom-slide{
    background-color: orange;
} */

.swiper-pagination-bullet {
    background-color: orange !important;
    height: 12px;
    width: 12px;
}

.swiper-slide-active .custom-slide {
    box-shadow: 0px 4px 48px 0px rgba(255, 181, 69, 0.72);
    transform: translateY(50px);
    transition: 0.3s ease-in;
    background-color: orange;
  }